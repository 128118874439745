// / <reference path="../../../types/react-simple-rotating-text.d.ts" />
import { Avatar, Box, Typography } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
// the package says, it has no export "RotatingText", but it works...
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { RotatingText } from "react-simple-rotating-text";

const boardUserAvatarImages = [
  "/app/user/user1.jpeg",
  "/app/user/user2.jpeg",
  "/app/user/user3.jpeg",
];

const RVOPeopleTrustResolvio = (): JSX.Element => {
  const { t } = useTranslation();

  const rollingUserRoleNames = [
    t("home.trustAvatars.roles.gesellschafter"),
    t("home.trustAvatars.roles.vorstaende"),
    t("home.trustAvatars.roles.geschaeftsführer"),
    t("home.trustAvatars.roles.beiraete"),
    t("home.trustAvatars.roles.vereinsmitglieder"),
    t("home.trustAvatars.roles.aufsichtsräte"),
    t("home.trustAvatars.roles.aktionäre"),
    t("home.trustAvatars.roles.verwaltungsräte"),
  ];

  return (
    <Box
      display="flex"
      flexDirection="row"
      style={{ gap: "1rem" }}
      alignItems="center"
    >
      <AvatarGroup>
        {boardUserAvatarImages.map((avatar) => (
          <Avatar key={avatar} src={avatar} />
        ))}
      </AvatarGroup>

      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={{ gap: 1 }}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {"500+ "}

            <RotatingText texts={rollingUserRoleNames} duration={2} />
          </Typography>
        </Box>

        <Typography variant="body1">{t("home.trustAvatars.text")}</Typography>
      </Box>
    </Box>
  );
};

export default RVOPeopleTrustResolvio;
